<template>
  <div>
    <primaryTextfield
      v-if="result.decodeType!='text'"
      v-model="localValue"
      :fieldAttrInput="{...fieldAttributes.fieldAttrInput,class:checkPath}"
      :label="fieldAttributes.name"
      :name="field"
      :templateContent="result"
      :hideDetails="hideDetails"
    ></primaryTextfield>
    <div v-else-if="result.decodeType=='text'" class="mt-2">Content</div>
    <div v-else :class="'mt-2 '+field">{{localValue}}</div>
  </div>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";

export default {
  components: { primaryTextfield },
  props: ["result", "fieldAttributes", "field","value","hideDetails"],

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    checkPath() {
      let checkPath = "";
      let forWhat = this.result.decodeType;
      if (
        typeof forWhat != "undefined" &&
        (forWhat == "JSON" || forWhat == "jwt")
      ) {
        checkPath = "jsonPathCheck";
      } else if (
        typeof forWhat != "undefined" &&
        (forWhat == "text/xml" || forWhat == "XML")
      ) {
        checkPath = "xPathCheck";
      }
      return checkPath+ " required";
    },
  },
};
</script>